/** Kind */
export const Kind = {
  Solid: 'solid',
  Transparent: 'transparent',
  Outline: 'outline',
} as const;

export type Kind = typeof Kind[keyof typeof Kind];


/** Size */
export const Sizes = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small',
} as const;

export type Sizes = typeof Sizes[keyof typeof Sizes];


/** Hierarchy */
export const Hierarchy = {
  Action: 'action',
  Primary: 'primary',
  Secondary: 'secondary',
} as const;

export type Hierarchy = typeof Hierarchy[keyof typeof Hierarchy];

/** CalendarCardType */
export const CalendarCardState = {
  Title: 'title',
  TitleToday: 'today',
  Blank: 'blank',
  Multi: 'multi',
  Book: 'book',
  Break: 'break',
  Process: 'process',
  Done: 'done',
  NotDone: 'not-done',
  Work: 'work',
  Confirmed: 'confirmed',
  NotConfirmed: 'not-confirmed',
  Tech: 'tech',
  Parallel: 'parallel',
}

export type CalendarCardStateType = typeof CalendarCardState[keyof typeof CalendarCardState];

/** BackgroundType */
export const Background = {
  Gray: 'gray',
  Outline: 'outline',
} as const;

export type BackgroundType = typeof Background[keyof typeof Background];


/** Hierarchy */
export const DotState = {
  Active: 'active',
  Disabled: 'disabled',
} as const;

export type DotState = typeof DotState[keyof typeof DotState];

export const Bg = {
  Grey: 'grey',
  None: 'none',
} as const;

export type Bg = typeof Bg[keyof typeof Bg];

/** Badge */
export const BadgeType = {
  Violet: 'violet',
  Green: 'green',
  Gray: 'gray',
  Blue: 'blue',
  Yellow: 'yellow',
  Red: 'red',
  Lavender: 'lavender',
} as const;

export type BadgeType = typeof BadgeType[keyof typeof BadgeType];

/** Chip */
export const ChipType = {
  Default: 'default',
  Accent: 'accent',
  Success: 'success',
  Disabled: 'disabled',
  Alert: 'alert',
} as const;

export type ChipType = typeof ChipType[keyof typeof ChipType];

/** Styles */
export const Styles = {
  Kind,
  Sizes,
  Background,
  Hierarchy,
  Bg,
  DotState,
  CalendarCardState,
  BadgeType,
  ChipType,
};
